import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Ls from './services/ls'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import jwtToken from './helpers/jwt-token'
import store from './store'
import router from './router'
import VueCookies from 'vue-cookies'

window._ = require('lodash');

/**
 * Font Awesome
 */
require('./plugins/font-awesome')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.baseURL = (process.env.NODE_ENV !== 'production') ? process.env.VUE_APP_LOCAL_BASE_URL : ''
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Axios setup
window.axios.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  if (jwtToken.getToken()) {
    config.headers['Authorization'] = 'Bearer ' + jwtToken.getToken();
  }

  return config;
}, error => {
  return Promise.reject(error);
});

// Axios response token verification
window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  let errorResponseData = error.response;

  if (errorResponseData.data && errorResponseData.statusText === "Unauthorized" && errorResponseData.status === 401 ) {
    store.dispatch('auth/unsetUser')
      .then(() => {
        router.push({name: 'login'});
      });
  }

  return Promise.reject(error);
});

Vue.use(Vuelidate)

Vue.use(VueSweetalert2)

window.Vue = require('vue')

window.Ls = Ls
global.$ = global.jQuery = require('jquery')

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


/**
 * Global plugins
 */
window.toastr = require('toastr')

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueCookies)
Vue.$cookies.config('7d')

