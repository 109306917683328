import * as types from './mutation-types'

export default {
  [types.UPDATE_APP_LOADING_STATUS]: (state, data) => {
    state.isAppLoaded = data
  },
  [types.SET_GLOBAL_SETTINGS]: (state, data) => {
    state.global_settings = data
  },
  [types.SET_BOTTOM_NAV]: (state, data) => {
    state.bottomNav = data
  }
}
