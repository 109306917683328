import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters'
import mutations from './mutations'
import actions from './actions'
import banner from './modules/banner'
// import auth from './modules/auth'
// import user from './modules/user'
// import role from './modules/role'
// import profile from './modules/profile'
// import analytic from './modules/analytic'

Vue.use(Vuex)

const initialState = {
  isAppLoaded: false,
  global_settings: null,
  bottomNav: 'home'
}

export default new Vuex.Store({
  strict: true,
  state: initialState,
  getters,
  mutations,
  actions,

  modules: {
      banner
    // auth,
    // user,
    // role,
    // profile,
    // analytic
  }
})
