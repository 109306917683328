import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faTachometerAlt,
  faFileAlt,
  faCreditCard,
  faSpaceShuttle,
  faSignal,
  faCog,
  faPlus,
  faComment,
  faMoneyBill,
  faBell,
  faHashtag,
  faSave,
  faFilter,
  faEdit,
  faTrash,
  faTrashAlt,
  faBackward,
  faShoppingBasket,
  faTimes,
  faUserPlus,
  faCartPlus,
  faArrowLeft,
  faArrowRight,
  faSpinner,
  faSignOutAlt,
  faCogs,
  faPencilAlt,
  faPrint,
  faCheck,
  faSync,
  faSortAmountDown,
  faSortAmountUp,
  faSortDown,
  faSearch,
  faAsterisk,
  faInfoCircle,
  faCheckCircle,
  faStar,
  faFile,
  faCloudUploadAlt,
  faDownload,
  faGripVertical,
  faCalendar,
  faFilePdf,
  faTimesCircle,
  faEnvelope,
  faEye,
  faShare,
  faEllipsisH,
  faCopy,
  faPaperPlane,
  faEyeSlash,
  faSyncAlt,
  faRocket,
  faCamera,
  faScroll,
  faCircle,
  faInfo,
  faUserGraduate,
  faAddressCard,
  faChevronDown,
  faChevronUp,
  faBuilding,
  faFileExcel,
  faUserCircle,
  faFileMedicalAlt,
  faChevronLeft,
  faReceipt,
  faFolderOpen,
  faQuestionCircle,
  faBook,
  faExternalLinkAlt,
  faMoneyCheckAlt,
  faBarcode
} from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

window.Vue = require('vue')

library.add(
  far,
  faUser,
  faTrashAlt,
  faTachometerAlt,
  faFileAlt,
  faFile,
  faStar,
  faCreditCard,
  faSpaceShuttle,
  faSignal,
  faCog,
  faPlus,
  faMoneyBill,
  faComment,
  faBell,
  faHashtag,
  faSave,
  faFilter,
  faEdit,
  faTrash,
  faBackward,
  faShoppingBasket,
  faTimes,
  faTimesCircle,
  faUserPlus,
  faCartPlus,
  faArrowLeft,
  faArrowRight,
  faCartPlus,
  faSpinner,
  faSignOutAlt,
  faCogs,
  faPencilAlt,
  faPrint,
  faCheck,
  faSync,
  faSortAmountDown,
  faSortAmountUp,
  faSortDown,
  faSearch,
  faAsterisk,
  faInfoCircle,
  faCheckCircle,
  faPencilAlt,
  faCloudUploadAlt,
  faDownload,
  faGripVertical,
  faCalendar,
  faFilePdf,
  faEnvelope,
  faEye,
  faEyeSlash,
  faShare,
  faEllipsisH,
  faCopy,
  faPaperPlane,
  faSyncAlt,
  faRocket,
  faCamera,
  faScroll,
  faCircle,
  faInfo,
  faUserGraduate,
  faAddressCard,
  faChevronUp,
  faChevronDown,
  faBuilding,
  faFileExcel,
  faUserCircle,
  faFileMedicalAlt,
  faChevronLeft,
  faReceipt,
  faFolderOpen,
  faQuestionCircle,
  faBook,
  faExternalLinkAlt,
  faMoneyCheckAlt,
  faBarcode
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
