import * as types from './mutation-types'
import * as authTypes from './modules/auth/mutation-types'
import * as roleTypes from './modules/role/mutation-types'
import * as profileTypes from './modules/profile/mutation-types'


export default {
  // eslint-disable-next-line no-unused-vars
  bootstrap ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      window.axios.get('/api/app/bootstrap').then((res) => {
        commit('auth/' + authTypes.SET_USER, res.data.user)
        commit('role/' + roleTypes.SET_ROLES, res.data.roles)
        commit('profile/' + profileTypes.SET_PROFILE, res.data.profile)
        commit(types.UPDATE_APP_LOADING_STATUS, true)
        commit(types.SET_GLOBAL_SETTINGS, res.data.global_settings)
        resolve(true);
      }).catch(err => {
        // eslint-disable-next-line no-empty
        if (err.response.data.error === 'invalid_credentials') {

        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', err.message)
        }
        reject(err)
      })
    })
  }
}
