import Vue from 'vue';
import App from './App.vue';
import store from './store/index';
import router from './router';
import vuetify from './plugins/vuetify';

// scss
import './scss/app.scss';

Vue.config.devtools = true;

Vue.config.productionTip = false;

require('./bootstrap');

window.$ = window.jQuery = require('jquery');

// components
import AppHeader from './components/layout/AppHeader';

import i18n from './i18n';

Vue.component('app-header', AppHeader);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
