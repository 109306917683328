import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Auth
// -------------------------------------
// import Layout from './views/layouts/Layout';
// import Login from './views/auth/Login';
// import jwtToken from './helpers/jwt-token';
// import store from './store/index';
// import LayoutBasic from './views/layouts/LayoutBasic';
//import LayoutPublic from './views/layouts/LayoutPublic';
// import HomeIndex from './views/home/Index';
// import ProfilePublic from './views/profile/Public';
// import AnalyticIndex from './views/analytics/Index';
// import SettingIndex from './views/settings/Index';
// import AccountDetail from './views/settings/AccountDetail';
// import Home from './views/Home';
// import TestPayment from './views/payment/TestPayment';

const routes = [
//   {
//     path: '/login',
//     component: Layout,
//     meta: { redirectIfAuthenticated: true },
//     children: [
//       {
//         path: '/',
//         name: 'login',
//         component: Login,
//       },
//     ],
//   },
//   {
//     path: '/',
//     component: LayoutBasic, // Change the desired Layout here
//     meta: { requiresAuth: true, isProfileSetup: true },
//     children: [
//       {
//         path: '/',
//         component: HomeIndex,
//         name: 'home',
//       },
//       {
//         path: 'home',
//         component: HomeIndex,
//         name: 'home.index',
//       },
//       {
//         path: 'analytics',
//         component: AnalyticIndex,
//         name: 'analytics.index',
//       },
//       {
//         path: 'settings',
//         component: SettingIndex,
//         name: 'settings.index',
//       },
//       {
//         path: 'account-details',
//         component: AccountDetail,
//         name: 'settings.account-details',
//       },
//     ],
//   },

//   {
//     path: '/profile',
//     component: LayoutBasic,
//     children: [
//       {
//         path: ':url_name',
//         component: ProfilePublic,
//         meta: { public: true },
//         name: 'profile.public',
//       },
//     ],
//   },
    // {
    //     path: '/banner/cms/form',
    //     component: Home,
    //     name: 'home',
    //   },
    //   {
    //     path: '/payment',
    //     component: TestPayment,
    //     name: 'payment',
    //   },
];

const router = new Router({
  routes,
  mode: 'history',
  base: '/',
  linkExactActiveClass: 'active',
});

// router.beforeEach(async (to, from, next) => {
//   if (jwtToken.getToken() && !store.getters['isAppLoaded']) {
//     await store.dispatch('bootstrap');
//   }

//   if (
//     to.matched.some((m) => m.meta.redirectIfAuthenticated) &&
//     store.getters['auth/isAuthenticated']
//   ) {
//     return next({ name: 'home.index' });
//   }


//   if (to.matched.some((m) => m.meta.public)) {
//     return next();
//   }

//   // requires authentication
//   if (to.matched.some((m) => m.meta.requiresAuth)) {
//     if (store.getters['isAppLoaded'] || jwtToken.getToken()) {
//       return next();
//     } else {
//       return next({ name: 'login' });
//     }
//   }

//   return next();
// });

export default router;
