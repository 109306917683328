<template>
    <v-img src="/assets/img/app/home/home-logo.png" class="app-home-logo mx-auto"></v-img>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
    .app-home-logo {
        width: 6em;
        margin: 2em 0 0.7em 0;
        text-align: center;
    }
</style>
