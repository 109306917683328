import * as types from './mutation-types'

// eslint-disable-next-line no-unused-vars
export const fetchPromotions = ({ commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/v1/g5/getPromotionList`).then((res) => {
            if(res.data.success){
                commit(types.SET_PROMOTION, res.data.results)
            }
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const fetchVideos = ({ commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/v1/g5/getVideoList`).then((res) => {
            if(res.data.success){
                commit(types.SET_VIDEO, res.data.results)
            }
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchNews = ({ commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/v1/g5/getNewsList`).then((res) => {
            if(res.data.success){
                commit(types.SET_NEWS, res.data.results)
            }
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const createPromotion = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/v1/g5/createPromotion`, data).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const createVideo = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/v1/g5/createVideo`, data).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const createNews = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/v1/g5/createNews`, data).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updatePromotion = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/v1/g5/updatePromotion/${data.id}`, data.formData).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateVideo = ({ commit}, data) => {
    console.log('from action', data.id)
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/v1/g5/updateVideo/${data.id}`, data.formData).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const updateNews = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/v1/g5/updateNews/${data.id}`, data.formData).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deletePromotion = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/v1/g5/deletePromotion/${data}`).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteVideo = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/v1/g5/deleteVideo/${data}`).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteNews = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/v1/g5/deleteNews/${data}`).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

// eslint-disable-next-line no-unused-vars
export const deleteImage = ({ commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/v1/g5/deleteImage/${data}`).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

