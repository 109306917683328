import * as types from './mutation-types'

export default {
  [types.SET_PROMOTION] (state, promotions) {
    state.promotions = promotions
  },
  [types.SET_VIDEO] (state, videos) {
    state.videos = videos
  },
  [types.SET_NEWS] (state, news) {
    state.news = news
  },
}
